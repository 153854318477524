<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="appeals"
            :config="config"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            addButtonTitle="+ Зарегистрировать"
            @onChangeData="onChangeData"
            @handleActions="handle"
        ></crm-table-pageable-new>
      </v-col>
    </v-row>
    <edit-appeal-dialog v-if="editAppealDialogVisible"
                        :visible="editAppealDialogVisible"
                        :appeal-id="openedAppealItem.id"
                        :appeal-reasons="appealReasons"
                        :appeal-statuses="appealStatuses"
                        :appeal-methods="appealMethods"
                        :appeal-levels="appealLevels"
                        :managers="filtersDictionaries.managers"
                        :executors="filtersDictionaries.executors"
                        @close-dialog="closeEditDialog"/>
    <new-appeal-dialog v-if="newAppealDialogVisible"
                       :visible="newAppealDialogVisible"
                       :appeal-reasons="appealReasons"
       :appeal-methods="appealMethods.filter((i) => appealMethodsToCreate.indexOf(i.code) !== -1)"
                       :appeal-statuses="appealStatuses"
                       :appeal-levels="appealLevels"
                       :managers="filtersDictionaries.managers"
                       :executors="filtersDictionaries.executors"
                       @close-dialog="closeNewDialog"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import moment from 'moment';
import EditAppealDialog from './components/EditAppealsDialog.vue';
import NewAppealDialog from './components/NewAppealDialog.vue';

export default {
  name: 'index',
  components: {
    'crm-table-pageable-new': crmTablePageableNew,
    EditAppealDialog,
    NewAppealDialog,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: true, text: '№', type: 'defaultItem', width: '65',
        },
        {
          value: 'appealMethod', sortable: false, text: 'Способ обращения', type: 'enums', enums: 'appealMethodEnums',
        },
        {
          value: 'contactName', sortable: false, text: 'Имя клиента', type: 'defaultItem',
        },
        {
          value: 'contactPhone', sortable: true, text: 'Номер телефона', type: 'defaultItem',
        },
        {
          value: 'appealReason', sortable: false, text: 'Тема обращения', type: 'enums', enums: 'appealReasonEnums',
        },
        {
          value: 'created', sortable: true, text: 'Дата начала', type: 'defaultItem',
        },
        {
          value: 'finishModified', sortable: true, text: 'Дата завершения', type: 'defaultItem',
        },
        {
          value: 'appealLevel', sortable: false, text: 'Уровень', type: 'enums', enums: 'appealLevelEnums',
        },
        {
          value: 'appealStatus', sortable: false, text: 'Статус', type: 'enums', enums: 'appealStatusEnums',
        },
        {
          value: 'managerName', sortable: false, text: 'Менеджер', type: 'defaultItem',
        },
        {
          value: 'executorName', sortable: false, text: 'Исполнитель', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
          ],
        },
      ],
      appeals: [],
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'appealMethods', name: 'Способ обращения', type: 'select', active: false,
        },
        {
          id: 'appealStatuses', name: 'Статус', type: 'select', active: false,
        },
        {
          id: 'appealLevels', name: 'Уровень', type: 'select', active: false,
        },
        {
          id: 'appealReasons', name: 'Тема обращения', type: 'select', active: false,
        },
        {
          id: 'managers', name: 'Менеджеры', type: 'select', active: false,
        },
        {
          id: 'executors', name: 'Исполнители', type: 'select', active: false,
        },
      ],
      appealStatuses: [],
      appealLevels: [],
      appealReasons: [],
      appealMethods: [],
      appealMethodsToCreate: [
        'MOBILE',
        'REQUEST',
        'WHATSAPP',
        'TELEGRAM',
      ],
      newAppealDialogVisible: false,
      editAppealDialogVisible: false,
      openedAppealItem: null,
      config: {
        add: true,
        search: true,
        searchPlaceholder: 'Поиск',
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/appeal',
      options: {
        page: 1,
        size: 10,
        sort: 'created,desc',
      },
    };
  },
  async created() {
    this.$loading(true);
    await this.getManagers();
    await this.getExecutors();
    await this.getAppealStatuses();
    await this.getAppealLevels();
    await this.getAppealReasons();
    await this.getAppealMethods();
    this.$loading(false);
    this.onChangeData(this.$route.query);
  },
  methods: {
    getManagers() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$appeals.get('/appeal/manager').then((res) => {
          this.appealStatuses = res;
          this.$set(this.filtersDictionaries, 'managers', res.map((i) => ({
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          })));
          resolve();
        });
      });
    },
    getExecutors() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$appeals.get('/appeal/executor').then((res) => {
          this.appealStatuses = res;
          this.$set(this.filtersDictionaries, 'executors', res.map((i) => ({
            value: i.id,
            label: `${i.firstName} ${i.lastName}`,
          })));
          resolve();
        });
      });
    },
    getAppealStatuses() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$appeals.get('/appeal/status').then((res) => {
          this.appealStatuses = res;
          this.$set(this.filtersDictionaries, 'appealStatuses', res.map((i) => ({
            value: i.code,
            label: i.nameRu,
          })));
          resolve();
        });
      });
    },
    getAppealLevels() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$appeals.get('/appeal/level').then((res) => {
          this.appealLevels = res;
          this.$set(this.filtersDictionaries, 'appealLevels', res.map((i) => ({
            value: i.code,
            label: i.nameRu,
          })));
          resolve();
        });
      });
    },
    getAppealReasons() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$dictionary.get('/appeal-reason').then((res) => {
          this.appealReasons = res;
          this.$set(this.filtersDictionaries, 'appealReasons', res.map((i) => ({
            value: i.code,
            label: i.nameRu,
          })));
          resolve();
        });
      });
    },
    getAppealMethods() {
      return new Promise((resolve) => {
        this.$loading(true);
        this.$appeals.get('/appeal/method').then((res) => {
          this.appealMethods = res;
          this.$set(this.filtersDictionaries, 'appealMethods', res.map((i) => ({
            value: i.code,
            label: i.nameRu,
          })));
          resolve();
        });
      });
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getAppeals();
    },
    getAppeals() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$appeals.get(formattedUrl, {
        params: {
          PageNumber: params.page,
          PageSize: params.size,
          Sorting: params.sort,
          SearchText: params.search,
          MethodFilter: params.appealMethods,
          ReasonFilter: params.appealReasons,
          LevelFilter: params.appealLevels,
          StatusFilter: params.appealStatuses,
          ManagerFilter: params.managers,
          ExecutorFilter: params.executors,
        },
      }).then((response) => {
        this.totalElements = response.recordsFiltered;
        this.totalPages = Math.ceil(response.recordsFiltered / (params.size || 1));
        this.appeals = response.content.map((i) => ({
          ...i,
          created: moment(i.created).format('DD-MM-YYYY HH:mm'),
          finishModified: i.finishModified ? moment(i.finishModified).format('DD-MM-YYYY HH:mm') : '',
        }));
      }).finally(() => {
        this.$loading(false);
      });
    },
    openNewDialog() {
      this.newAppealDialogVisible = true;
    },
    openEditDialog(item) {
      this.openedAppealItem = {
        ...item,
        manager: {
          label: item.managerName,
          value: item.managerId,
        },
        executor: {
          label: item.executorName,
          value: item.executorId,
        },
      };
      this.editAppealDialogVisible = true;
    },
    handle(element) {
      switch (element.type) {
        case 'edit':
          this.openEditDialog(element.item);
          break;
        case 'create':
          this.openNewDialog();
          break;
        default:
          break;
      }
    },
    closeNewDialog() {
      this.newAppealDialogVisible = false;
      this.onChangeData(this.$route.query);
    },
    closeEditDialog() {
      this.editAppealDialogVisible = false;
      this.openedAppealItem = null;
      this.onChangeData(this.$route.query);
    },
  },
};
</script>

<style lang="scss">

</style>
