<template>
<v-dialog v-model="visible"
          width="1200"
          content-class="appeal-edit"
          @click:outside="closeDialog"
          @keydown.esc="closeDialog">
  <v-card>
    <v-card-title class="card-title elevation-3">
      <span class="mr-3">Обращение #{{ appealItem.id }} от {{ formattedCreatedDate }}</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDialog">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text class="pt-5">
      <v-form ref="appealEdit" v-model="valid">
        <v-row>
          <v-col cols="4">
            <v-select label="Способ обращения"
                      item-text="nameRu"
                      item-value="code"
                      :rules="isEmptyRule"
                      disabled
                      v-model="appealItem.appealMethod"
                      :items="appealMethods"
                      persistent-placeholder></v-select>
            <v-text-field label="Имя клиента"
                          disabled
                          v-model="appealItem.contactName"
                          :rules="isEmptyRule"
                          persistent-placeholder></v-text-field>
            <v-text-field label="Номер телефона"
                          disabled
                          v-model="appealItem.contactPhone"
                          placeholder="+7(___)___-__-__"
                          v-mask="'+7(###)###-##-##'"
                          :rules="isEmptyRule"
                          persistent-placeholder></v-text-field>
            <v-select label="Тема обращения"
                      item-text="nameRu"
                      item-value="code"
                      disabled
                      v-model="appealItem.appealReason"
                      :items="appealReasons"
                      :rules="isEmptyRule"
                      persistent-placeholder></v-select>
          </v-col>
          <v-col cols="8">
            <v-select label="Статус"
                      :items="appealStatuses"
                      item-text="nameRu"
                      item-value="code"
                      disabled
                      v-model="appealItem.appealStatus"
                      :rules="isEmptyRule"
                      persistent-placeholder></v-select>
            <v-select label="Уровень"
                      :items="appealLevels"
                      item-text="nameRu"
                      item-value="code"
                      :disabled="completedAppeal"
                      :rules="isEmptyRule"
                      v-model="appealItem.appealLevel"
                      persistent-placeholder></v-select>
            <v-autocomplete label="Менеджер"
                      :items="managers"
                      item-text="label"
                      item-value="value"
                      v-model="appealItem.manager"
                      :rules="[(v) => (!!v && !!v.value) || 'Обязательное поле',]"
                      :disabled="loading || completedAppeal"
                      return-object
                      validate-on-blur
                      persistent-placeholder></v-autocomplete>
            <v-autocomplete label="Исполнитель"
                      :items="executors"
                      item-text="label"
                      item-value="value"
                      v-model="appealItem.executor"
                      :disabled="loading || completedAppeal"
                      return-object
                      persistent-placeholder></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pt-0">
            <div class="text-area-block">
              <span class="text-area-block__title">Обращение</span>
              <v-textarea readonly
                          class="text-area-block__input mt-1"
                          persistent-placeholder
                          filled
                          hide-details
                          v-model="appealItem.messageText"
                          rows="4"></v-textarea>
            </div>
          </v-col>
        </v-row>
        <v-row class="appeal-edit__answer">
          <v-col cols="12">
            <div class="text-area-block">
              <span class="text-area-block__title">Ответ</span>
              <v-form ref="appealAnswer" v-model="validAnswer">
                <v-textarea v-model="appealItem.answer"
                            filled
                            :readonly="completedAppeal"
                            :rules="!completedAppeal ? answerRule : []"
                            class="text-area-block__input mt-1"
                            placeholder="Введите ответ на обращение..."
                            persistent-placeholder
                            rows="4"></v-textarea>
              </v-form>
            </div>
          </v-col>
          <v-col cols="12" class="answer__actions pt-0">
            <v-btn @click="sendAnswer"
                   :disabled="answerSent || completedAppeal"
                   color="#007450"
                   class="send-btn">
              {{ answerSent ? 'Отправлено' : 'Отправить' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="appeal-edit__actions">
      <v-btn text
             rounded
             color="primary"
             class="cancel"
             @click="closeDialog">
        <span class="px-2">Отмена</span>
      </v-btn>
      <v-btn @click="saveAppeal"
             v-if="!completedAppeal"
             color="#007450"
             class="save">
        Сохранить
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import moment from 'moment';

export default {
  name: 'AppealsDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appealId: {
      type: Number,
    },
    appealStatuses: {
      type: Array,
      default: () => [],
    },
    appealLevels: {
      type: Array,
      default: () => [],
    },
    appealReasons: {
      type: Array,
      default: () => [],
    },
    appealMethods: {
      type: Array,
      default: () => [],
    },
    managers: {
      type: Array,
      default: () => [],
    },
    executors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: false,
      validAnswer: false,
      isEmptyRule: [
        (v) => !!v || 'Обязательное поле',
      ],
      answerRule: [
        (v) => !!v || 'Обязательное поле',
        (v) => (!!v && v.length >= 10) || 'Минимально количество символов - 10',
      ],
      loading: false,
      loadingManagers: false,
      loadingExecutors: false,
      timerId: null,
      searchManagerText: '',
      searchExecutorText: '',
      appealItem: {
        id: null,
        appealMethod: null,
        contactName: null,
        contactPhone: null,
        appealReason: null,
        appealLevel: null,
        appealStatus: null,
        messageText: null,
        answer: null,
        manager: null,
        executor: null,
      },
    };
  },
  computed: {
    formattedCreatedDate() {
      return moment(this.appealItem.created).isValid()
        ? moment(this.appealItem.created).format('DD.MM.YYYY')
        : this.appealItem.created;
    },
    answerSent() {
      return ['FINISHED'].indexOf(this.appealItem.appealStatus) !== -1;
    },
    completedAppeal() {
      return ['FINISHED', 'CANCELED', 'EXPIRED'].indexOf(this.appealItem.appealStatus) !== -1;
    },
  },
  created() {
    if (this.appealId) {
      this.$appeals.get(`/appeal/${this.appealId}`).then((res) => {
        this.appealItem = {
          ...res.content,
          manager: {
            label: res.content.managerName,
            value: res.content.managerId,
          },
          executor: {
            label: res.content.executorName,
            value: res.content.executorId,
          },
        };
      });
    }
  },
  methods: {
    searchManagers() {
      if (!this.searchManagerText) this.searchManagerText = '';
      if (this.searchManagerText) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.loadingManagers = true;
          this.$appeals.get('/appeal/manager', {
            params: {
              SearchText: this.searchManagerText,
            },
          }).then((response) => {
            this.managers = [...response].map((i) => ({
              value: i.id,
              label: `${i.firstName} ${i.lastName}`,
            }));
          }).finally(() => {
            this.loadingManagers = false;
          });
        }, 1000);
      }
    },
    searchExecutors() {
      if (!this.searchExecutorText) this.searchExecutorText = '';
      if (this.searchExecutorText) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.loadingExecutors = true;
          this.$appeals.get('/appeal/executor', {
            params: {
              SearchText: this.searchExecutorText,
            },
          }).then((response) => {
            this.executors = [...response].map((i) => ({
              value: i.id,
              label: `${i.firstName} ${i.lastName}`,
            }));
          }).finally(() => {
            this.loadingExecutors = false;
          });
        }, 1000);
      }
    },
    checkAppealFields() {
      return this.$refs.appealEdit.validate();
    },
    checkAppealAnswer() {
      return this.$refs.appealAnswer.validate();
    },
    async sendAnswer() {
      const appealFieldsValid = await this.checkAppealFields();
      const answerValid = await this.checkAppealAnswer();
      if (appealFieldsValid && answerValid) {
        this.loading = true;
        await this.putAppeal();
        await this.$appeals.post('/appeal/answer', {
          id: this.appealItem.id,
          answer: this.appealItem.answer,
        }).then((res) => {
          this.appealItem = {
            ...this.appealItem,
            ...res.content,
          };
          this.$toast.open({
            message: 'Ответ на обращение отправлен',
            type: 'success',
          });
        }).finally(() => {
          this.loading = false;
        });
      } else {
        this.$toast.open({
          message: 'Заполните поля',
          type: 'error',
        });
      }
    },
    async putAppeal() {
      return this.$appeals.put('/appeal/save', {
        id: this.appealItem.id,
        appealMethod: this.appealItem.appealMethod,
        contactName: this.appealItem.contactName,
        contactPhone: this.appealItem.contactPhone,
        appealReason: this.appealItem.appealReason,
        appealStatus: this.appealItem.appealStatus,
        appealLevel: this.appealItem.appealLevel,
        managerId: this.appealItem.manager.value,
        managerName: this.appealItem.manager.label,
        executorId: this.appealItem.executor.value,
        executorName: this.appealItem.executor.label,
        messageText: this.appealItem.messageText,
        answer: this.appealItem.answer,
      }).then((res) => {
        this.$emit('update:appealItem', res.content);
        this.$toast.open({
          message: 'Обращение успешно изменено!',
          type: 'success',
        });
      });
    },
    async saveAppeal() {
      if (this.$refs.appealEdit.validate()) {
        await this.putAppeal();
      } else {
        this.$toast.open({
          message: 'Заполните поля',
          type: 'error',
        });
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss">
.appeal-edit {
  overflow-y: hidden;
  .v-card__text {
    max-height: calc(100vh - 220px);
    overflow-y: auto;
  }
  .text-area-block {
    &__title {
      font-size: 16px;
      margin-left: 2px;
    }
    &__input {
      border-radius: 10px;

      .v-input__slot {
        padding: 0 15px 5px 10px;
      }
    }
  }
  &__answer {
    .col {
      padding: 0 12px 0 12px;
    }
    .answer__actions {
      padding: 0 12px 0 12px;
      .send-btn {
        color: #FFFFFF;
        border-radius: 18px
      }
    }
  }
  &__actions {
    justify-content: end;
    padding-bottom: 20px!important;
    .save {
      color: #FFFFFF;
      border-radius: 18px
    }
    .cancel {

    }
  }
}
</style>
