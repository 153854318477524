<template>
  <v-dialog v-model="visible"
            width="1200"
            content-class="appeal-new"
            @click:outside="closeDialog"
            @keydown.esc="closeDialog">
    <v-card>
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Регистрация обращения</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pt-3">
        <v-form ref="appealNew" v-model="valid">
          <v-row>
            <v-col cols="4">
              <v-select label="Способ обращения"
                        item-text="nameRu"
                        item-value="code"
                        v-model="appealItem.appealMethod"
                        :items="appealMethods"
                        :rules="isEmptyRule"
                        :disabled="loading"
                        persistent-placeholder></v-select>
              <v-text-field label="Имя клиента"
                            v-model="appealItem.contactName"
                            :rules="isEmptyRule"
                            :disabled="loading"
                            persistent-placeholder></v-text-field>
              <v-text-field label="Номер телефона"
                            v-model="appealItem.contactPhone"
                            :rules="phoneNumberRule"
                            :disabled="loading"
                            placeholder="+7(___)___-__-__"
                            v-mask="'+7(###)###-##-##'"
                            persistent-placeholder></v-text-field>
              <v-select label="Тема обращения"
                        item-text="nameRu"
                        item-value="code"
                        v-model="appealItem.appealReason"
                        :items="appealReasons"
                        :rules="isEmptyRule"
                        :disabled="loading"
                        persistent-placeholder></v-select>
            </v-col>
            <v-col cols="8">
              <v-select label="Статус"
                        :items="appealStatuses"
                        item-text="nameRu"
                        item-value="code"
                        v-model="appealItem.appealStatus"
                        :rules="isEmptyRule"
                        :disabled="loading"
                        persistent-placeholder></v-select>
              <v-select label="Уровень"
                        :items="appealLevels"
                        item-text="nameRu"
                        item-value="code"
                        v-model="appealItem.appealLevel"
                        :rules="isEmptyRule"
                        :disabled="loading"
                        persistent-placeholder></v-select>
              <v-autocomplete label="Менеджер"
                        :items="managers"
                        item-text="label"
                        item-value="value"
                        v-model="appealItem.manager"
                        :rules="isEmptyRule"
                        :disabled="loading"
                        return-object
                        persistent-placeholder></v-autocomplete>
              <v-autocomplete label="Исполнитель"
                        :items="executors"
                        item-text="label"
                        item-value="value"
                        v-model="appealItem.executor"
                        :disabled="loading"
                        return-object
                        persistent-placeholder></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <div class="text-area-block">
                <span class="text-area-block__title">Обращение</span>
                <v-textarea filled
                            rounded
                            :disabled="loading"
                            class="text-area-block__input mt-1"
                            persistent-placeholder
                            placeholder="Введите текст обращения..."
                            :rules="isEmptyRule"
                            v-model="appealItem.messageText"
                            rows="4"></v-textarea>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="appeal-new__actions">
        <v-btn text
               rounded
               :disabled="loading"
               color="primary"
               class="cancel"
               @click="closeDialog">
          <span class="px-2">Отмена</span>
        </v-btn>
        <v-btn @click="saveAppeal"
               color="#007450"
               :disabled="loading"
               class="save">
          Создать
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'NewAppealDialog',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    appealStatuses: {
      type: Array,
      default: () => [],
    },
    appealLevels: {
      type: Array,
      default: () => [],
    },
    appealReasons: {
      type: Array,
      default: () => [],
    },
    appealMethods: {
      type: Array,
      default: () => [],
    },
    managers: {
      type: Array,
      default: () => [],
    },
    executors: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      valid: false,
      isEmptyRule: [
        (v) => !!v || 'Обязательное поле',
      ],
      phoneNumberRule: [
        (v) => (!!v && v.length === 16) || 'Обязательное поле',
      ],
      appealItem: {
        appealMethod: null,
        contactName: null,
        contactPhone: null,
        appealReason: null,
        appealLevel: null,
        appealStatus: null,
        messageText: null,
        answer: null,
        manager: null,
        executor: null,
      },
      loading: false,
      loadingManagers: false,
      loadingExecutors: false,
      timerId: null,
      searchManagerText: '',
      searchExecutorText: '',
    };
  },
  methods: {
    searchManagers() {
      if (!this.searchManagerText) this.searchManagerText = '';
      if (this.searchManagerText) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.loadingManagers = true;
          this.$appeals.get('/appeal/manager', {
            params: {
              SearchText: this.searchManagerText,
            },
          }).then((response) => {
            this.managers = [...response].map((i) => ({
              value: i.id,
              label: `${i.firstName} ${i.lastName}`,
            }));
          }).finally(() => {
            this.loadingManagers = false;
          });
        }, 1000);
      }
    },
    searchExecutors() {
      if (!this.searchExecutorText) this.searchExecutorText = '';
      if (this.searchExecutorText) {
        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.loadingExecutors = true;
          this.$appeals.get('/appeal/executor', {
            params: {
              SearchText: this.searchExecutorText,
            },
          }).then((response) => {
            this.executors = [...response].map((i) => ({
              value: i.id,
              label: `${i.firstName} ${i.lastName}`,
            }));
          }).finally(() => {
            this.loadingExecutors = false;
          });
        }, 1000);
      }
    },
    saveAppeal() {
      if (this.$refs.appealNew.validate()) {
        this.loading = true;
        const body = {
          appealMethod: this.appealItem.appealMethod,
          appealReason: this.appealItem.appealReason,
          appealStatus: this.appealItem.appealStatus,
          appealLevel: this.appealItem.appealLevel,
          contactName: this.appealItem.contactName,
          contactPhone: this.appealItem.contactPhone,
          managerId: this.appealItem.manager.value,
          managerName: this.appealItem.manager.label,
          messageText: this.appealItem.messageText,
          answer: this.appealItem.answer,
        };

        if (this.appealItem && this.appealItem.executor) {
          body.executorId = this.appealItem.executor.value;
          body.executorName = this.appealItem.executor.label;
        }

        this.$appeals.post('/appeal/cms', body).then(() => {
          this.$store.dispatch('tools/setSnackbar', {
            type: 'success',
            message: 'Обращение создано!',
          });
          this.closeDialog();
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss">
.appeal-new {
  overflow-y: hidden;
  .v-card__text {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
  .text-area-block {
    &__title {
      font-size: 16px;
      margin-left: 2px;
    }
    &__input {
      border-radius: 10px;

      .v-input__slot {
        padding: 0 15px 5px 10px;
      }
    }
  }
  &__actions {
    justify-content: end;
    padding-bottom: 20px!important;
    .save {
      color: #FFFFFF;
      border-radius: 18px
    }
    .cancel {

    }
  }
}
</style>
